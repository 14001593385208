import classNames from "classnames";
import { ErrorMessage, FormikErrors, FormikTouched } from "formik";
import { TFunction } from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps, useSelector } from "react-redux";

import { AddIcon } from "components/icons/AddIcon";
import Info from "components/icons/Info";
import PreviewIcon from "components/icons/PreviewIcon";
import { BundlesTenantTypes, CorporateBundle, ProcessorBundle } from "components/licenses/bundles";
import BundleTokenPricingView from "components/licenses/BundleTokenPricingView";
import { getDeliveryTypes } from "components/licenses/common";
import style from "components/licenses/delivery-history/add-license-delivery.scss";
import infoBannerStyle from "components/licenses/delivery-history/delivery-form-banner.scss";
import {
    deduceTierLicenses,
    FormValues,
    generateSelectableLicenses,
} from "components/tenants/add-tenant/AddTenantForm";
import { disableBatchPoolUpdate } from "components/tenants/DeliveryTab";
import Tooltip from "components/tooltip/Tooltip";
import { ComputedLicenses, deliveryTypes, MAX_DATE } from "domain/licenses";
import { CombinedTier, LicensingModel, TenantType } from "domain/tenants";
import { generateTenantTypeToTranslationMap, generateTierToTranslationMap } from "domain/tierRelatedMaps";
import { extractProductRatesList, ProductToRateList } from "services/licenses/LicenseService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import {
    hasSubTenantCookie,
    isCurrentTenantInternal,
    isUserParentInternal,
} from "services/tenants/tenantCookieService";
import { StoreState } from "store";
import { replaceProductIdWithNameInRatesTable } from "store/license";
import buttons from "styles/buttons.scss";
import form from "styles/form.scss";
import { hasOnlyNumbers, isStringNotBlank } from "utils/commonFunctions";
import {
    formatDateWithoutTime,
    formatUtcDateString,
    HOUR,
    MINUTES_SECONDS_MILLISECONDS,
    toUtcDateString,
} from "utils/format";

import testIds from "testIds.json";

export interface DeliveryForm {
    deliveryType: string;
    caseNumber: string;
    opportunityId: string;
    notes: string;
    amount: null | number;
    expirationDate: string;
    tenantType?: TenantType | string;
    bundle?: string;
    tokenRateVersion: string;
    toPopulatePools?: boolean;
}

export interface License {
    index: number;
    licenseType: string;
    available: number;
    licensesToAdd: number;
    totalOfLicenses: number;
    expirationDate: string;
    productName: string;
    parentAvailableAmount: number;
    parentLicenseExpirationDate: string | null;
    assigned?: number;
}

export const validateParameters = (caseNumber: string | undefined, opportunityId: string | undefined) => {
    if (!isUserParentInternal()) {
        return false;
    }
    if (!hasSubTenantCookie()) {
        return true;
    }

    return isStringNotBlank(caseNumber) || isStringNotBlank(opportunityId);
};

export const isConfirmationDialogVisible = (deliveryType: string, opportunityId: string, caseNumber: string) => {
    return (
        deliveryType.trim() !== "" &&
        deliveryType !== DEFAULT_SELECT_DELIVERY_TYPE &&
        (validateParameters(caseNumber, opportunityId)
            ? isStringNotBlank(opportunityId) &&
              opportunityId.length <= MAX_OPPORTUNITY_ID_LENGTH &&
              hasOnlyNumbers(caseNumber) &&
              caseNumber.length <= MAX_CASE_NUMBER_LENGTH
            : true)
    );
};

export const addMoreLicenses = (color: string, linecolor: string, text: string) => {
    return (
        <>
            <AddIcon color={color} linecolor={linecolor} />
            <span className={style.addMoreButton}>{text}</span>
        </>
    );
};

export function createDefaultSelectedLicense(t: TFunction) {
    return {
        licenseType: "default",
        licensesToAdd: 0,
        productName: t("DeliveryHistory.addLicenseDelivery.table.default"),
        totalOfLicenses: 0,
        expirationDate: formatUtcDateString(
            formatDateWithoutTime(new Date().toString()),
            HOUR,
            MINUTES_SECONDS_MILLISECONDS,
            MINUTES_SECONDS_MILLISECONDS
        ),
        index: 0,
        available: 0,
        parentAvailableAmount: 0,
        parentLicenseExpirationDate: isCurrentTenantInternal() ? toUtcDateString(MAX_DATE) : null,
    };
}

export const MAX_CASE_NUMBER_LENGTH = 8;
export const MAX_OPPORTUNITY_ID_LENGTH = 32;
export const DESCRIPTION_MAX_LENGTH = 128;
const DEFAULT_SELECT_DELIVERY_TYPE = "delivery_type";

interface Props {
    availableLicenses: License[];
    ownedLicenses: License[];
    preselectedFromBundle?: boolean;
    deliveryForm: DeliveryForm;
    errors: FormikErrors<DeliveryForm> | FormikErrors<FormValues>;
    handleChange: (
        e:
            | React.ChangeEvent<HTMLSelectElement>
            | React.ChangeEvent<HTMLInputElement>
            | React.ChangeEvent<HTMLTextAreaElement>
    ) => void;
    setSelectedLicenses: (licenses: License[]) => void;
    selectedLicenses: License[];
    inTenantCreation: boolean;
    licensingModel: LicensingModel;
    rateVersions?: ProductToRateList[];
    selectedTier: CombinedTier;
    handleBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
    toUpdatePools?: boolean;
    equivalentCommonLicenses?: number;
    addOnLicenses?: ComputedLicenses[];
    validateOnChange?: boolean;
    touched?: FormikTouched<DeliveryForm>;
    tenantType: TenantType;
}

const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));

const DeliveryFormContent = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const theme = useSelector((state: StoreState) => state.themeReducer.theme);
    const DELIVERY_TYPES = getDeliveryTypes(props.inTenantCreation);
    const DEFAULT_SELECTION_VALUE = "DEFAULT";
    const [bundleOption, setBundleOption] = React.useState(DEFAULT_SELECTION_VALUE);
    const formErrors = props.errors as FormikErrors<DeliveryForm>;
    const [bundleTokenPricingModalVisible, setBundleTokenPricingModalVisible] = React.useState(false);
    const deducedTierLicenses = deduceTierLicenses(props.selectedTier, true, props.tenantType, true);

    function createElementId(id: string): string {
        return props.inTenantCreation ? "deliveryDetails." + id : id;
    }

    function extractRateUuid() {
        if (props.deliveryForm.tokenRateVersion) {
            return props.deliveryForm.tokenRateVersion;
        }
        if (typeof props.rateVersions === "undefined" || props.rateVersions.length < 1) {
            return "";
        }
        return props.rateVersions[0].uuid;
    }

    function createBatchPoolUpdate() {
        if (!props.toUpdatePools) {
            return null;
        }
        const disabled = disableBatchPoolUpdate(
            props.selectedLicenses,
            props.equivalentCommonLicenses,
            props.addOnLicenses
        );
        const checkBox = (
            <div>
                <input
                    id={createElementId("toPopulatePools")}
                    type="checkbox"
                    className={form.input}
                    onChange={props.handleChange}
                    checked={props.deliveryForm.toPopulatePools}
                    data-testid={testIds.workArea.license.deliveryHistory.createDeliveryDialog.toPopulatePoolsCheckbox}
                    disabled={disabled}
                />
                <span className={form.checkmark} />
            </div>
        );
        return (
            <div className={classNames(form.formFields, { [form.disabledTextColor]: disabled })}>
                <label htmlFor="toPopulatePools" className={classNames(form.label, style.wideWidth)}>
                    {t("DeliveryHistory.addLicenseDelivery.licensePoolsLabel")}
                </label>
                <label className={form.container}>
                    {disabled ? (
                        <Tooltip
                            placement={"top"}
                            content={
                                <div className={style.tooltipTextColor}>
                                    {t("DeliveryHistory.addLicenseDelivery.disabledBatchUpdate")}
                                </div>
                            }
                        >
                            {checkBox}
                        </Tooltip>
                    ) : (
                        checkBox
                    )}
                </label>
                <span>{t("DeliveryHistory.addLicenseDelivery.licensePoolsLabelDetails")}</span>
            </div>
        );
    }

    return (
        <>
            <div className={form.formFields}>
                <label htmlFor="deliveryType" className={classNames(form.label, style.wideWidth)}>
                    {t("DeliveryHistory.addLicenseDelivery.deliveryType.label")}
                </label>
                <select
                    id={createElementId("deliveryType")}
                    className={classNames(form.select, style.selectArrow, form.fixedWidthInput, {
                        [form.inputError]: props.touched?.deliveryType && formErrors.deliveryType,
                    })}
                    onChange={(event) => {
                        props.handleChange(event);
                    }}
                    value={props.deliveryForm.deliveryType}
                    onClick={() => {
                        usageStatisticsService.sendEvent({
                            category: Category.LICENSE_DELIVERY,
                            action: Action.CHANGE_DELIVERY_TYPE,
                        });
                    }}
                    disabled={props.inTenantCreation}
                    data-testid={
                        testIds.workArea.license.deliveryHistory.createDeliveryDialog.deliveryTypeSelect.itself
                    }
                >
                    {!props.inTenantCreation && (
                        <option key={DEFAULT_SELECT_DELIVERY_TYPE} value={DEFAULT_SELECT_DELIVERY_TYPE}>
                            {t("DeliveryHistory.addLicenseDelivery.deliveryType.type.default")}
                        </option>
                    )}
                    {deliveryTypes.map((value) => (
                        <option key={value} value={value}>
                            {DELIVERY_TYPES.get(value)}
                        </option>
                    ))}
                </select>
                <div
                    className={form.error}
                    data-testid={
                        testIds.workArea.license.deliveryHistory.createDeliveryDialog.deliveryTypeSelect.errorLabel
                    }
                >
                    {<ErrorMessage name={createElementId("deliveryType")} />}
                </div>
            </div>
            {props.preselectedFromBundle ? (
                <>
                    <div className={form.formFields}>
                        <label htmlFor="tenantType" className={classNames(form.label, style.wideWidth)}>
                            {t("DeliveryHistory.addLicenseDelivery.tenantTypeLabel")}
                        </label>
                        <select
                            id={createElementId("tenantType")}
                            className={classNames(form.select, style.selectArrow, form.fixedWidthInput)}
                            onChange={(event) => {
                                props.handleChange(event);
                                props.setSelectedLicenses([createDefaultSelectedLicense(t)]);
                                setBundleOption(DEFAULT_SELECTION_VALUE);
                            }}
                            value={props.deliveryForm.tenantType}
                            data-testid={
                                testIds.workArea.license.deliveryHistory.createDeliveryDialog.tenantTypeSelect.itself
                            }
                        >
                            <option key={"default tenant type"} value={DEFAULT_SELECTION_VALUE}>
                                {t("DeliveryHistory.addLicenseDelivery.defaultTenantType")}
                            </option>
                            {Object.keys(BundlesTenantTypes).map((value) => (
                                <option key={value} value={value}>
                                    {generateTenantTypeToTranslationMap(t).get(value as TenantType)}
                                </option>
                            ))}
                        </select>
                    </div>
                    {!props.inTenantCreation ? (
                        <div className={form.formFields}>
                            <label htmlFor="bundle" className={classNames(form.label, style.wideWidth)}>
                                {t("AddCustomerForm.blanccoBundles")}
                            </label>
                            <select
                                id={createElementId("bundle")}
                                className={classNames(form.select, style.selectArrow, form.fixedWidthInput)}
                                onChange={(event) => {
                                    props.handleChange(event);
                                    const selectedBundle = event.target.value;
                                    setBundleOption(selectedBundle);
                                    if (selectedBundle === DEFAULT_SELECTION_VALUE) {
                                        props.setSelectedLicenses([createDefaultSelectedLicense(t)]);
                                        return;
                                    }
                                    const preselectedLicenses: License[] = [];
                                    const licensesPerTier: {
                                        productName: string;
                                        productId: string;
                                    }[] = [];
                                    generateSelectableLicenses(
                                        licensesPerTier,
                                        selectedBundle,
                                        props.licensingModel,
                                        props.tenantType
                                    );
                                    licensesPerTier.forEach((eachLicenseInTier) => {
                                        const foundOwnedLicense = props.ownedLicenses.find(
                                            (each) => each.licenseType == eachLicenseInTier.productId
                                        );
                                        if (foundOwnedLicense) {
                                            if (props.deliveryForm.amount) {
                                                foundOwnedLicense.licensesToAdd = props.deliveryForm.amount;
                                            }
                                            if (props.deliveryForm.expirationDate) {
                                                foundOwnedLicense.expirationDate = props.deliveryForm.expirationDate;
                                            }
                                            preselectedLicenses.push(foundOwnedLicense);
                                        } else {
                                            const foundInAvailableLicenses = props.availableLicenses.find(
                                                (each) => each.licenseType == eachLicenseInTier.productId
                                            );
                                            if (foundInAvailableLicenses) {
                                                if (props.deliveryForm.amount) {
                                                    foundInAvailableLicenses.licensesToAdd = props.deliveryForm.amount;
                                                }
                                                if (props.deliveryForm.expirationDate) {
                                                    foundInAvailableLicenses.expirationDate =
                                                        props.deliveryForm.expirationDate;
                                                }
                                                preselectedLicenses.push(foundInAvailableLicenses);
                                            }
                                        }
                                    });
                                    props.setSelectedLicenses(preselectedLicenses);
                                }}
                                value={bundleOption}
                                data-testid={
                                    testIds.workArea.license.deliveryHistory.createDeliveryDialog.tenantTypeSelect
                                        .itself
                                }
                            >
                                <option key={"default bundle type"} value={DEFAULT_SELECTION_VALUE}>
                                    {t("DeliveryHistory.addLicenseDelivery.defaultBundle")}
                                </option>
                                {props.deliveryForm.tenantType === DEFAULT_SELECTION_VALUE
                                    ? null
                                    : props.deliveryForm.tenantType == "CORPORATE"
                                    ? Object.keys(CorporateBundle).map((value) => (
                                          <option key={value} value={value}>
                                              {generateTierToTranslationMap(t).get(value)}
                                          </option>
                                      ))
                                    : Object.keys(ProcessorBundle).map((value) => (
                                          <option key={value} value={value}>
                                              {generateTierToTranslationMap(t).get(value)}
                                          </option>
                                      ))}
                            </select>
                        </div>
                    ) : null}
                </>
            ) : null}
            {isUserParentInternal() && (
                <>
                    <div className={form.formFields}>
                        <label htmlFor="opportunityId" className={classNames(form.label, style.wideWidth)}>
                            {t("DeliveryHistory.addLicenseDelivery.opportunityId")}
                        </label>
                        <input
                            id={createElementId("opportunityId")}
                            className={classNames(form.input, form.fixedWidthInput, {
                                [form.inputError]: props.touched?.opportunityId && formErrors.opportunityId,
                            })}
                            onChange={(event) => {
                                props.handleChange(event);
                            }}
                            onBlur={(event) => props.handleBlur?.(event)}
                            value={props.deliveryForm.opportunityId}
                            data-testid={
                                testIds.workArea.license.deliveryHistory.createDeliveryDialog.opportunityIdInput.itself
                            }
                        />
                        <div
                            className={form.error}
                            data-testid={
                                testIds.workArea.license.deliveryHistory.createDeliveryDialog.opportunityIdInput
                                    .errorLabel
                            }
                        >
                            {<ErrorMessage name={createElementId("opportunityId")} />}
                        </div>
                    </div>

                    <div className={form.formFields}>
                        {!isUserParentInternal() ? <span className={form.optional}>{t("Common.optional")}</span> : null}
                        <label htmlFor="caseNumber" className={classNames(form.label, style.wideWidth)}>
                            {t("DeliveryHistory.addLicenseDelivery.caseNumber")}
                        </label>

                        <input
                            id={createElementId("caseNumber")}
                            className={classNames(form.input, form.fixedWidthInput, {
                                [form.inputError]: props.touched?.caseNumber && formErrors.caseNumber,
                            })}
                            onChange={(event) => {
                                props.handleChange(event);
                            }}
                            onBlur={(event) => props.handleBlur?.(event)}
                            value={props.deliveryForm.caseNumber}
                            data-testid={
                                testIds.workArea.license.deliveryHistory.createDeliveryDialog.caseNumberInput.itself
                            }
                        />
                        <div
                            className={form.error}
                            data-testid={
                                testIds.workArea.license.deliveryHistory.createDeliveryDialog.caseNumberInput.errorLabel
                            }
                        >
                            {<ErrorMessage name={createElementId("caseNumber")} />}
                        </div>
                    </div>
                </>
            )}

            <div className={classNames(form.formFields, form.formFieldsFlex)}>
                <div className={form.formFieldsAlignItemsTop}>
                    <span className={form.optional}>{t("Common.optional")}</span>
                    <label htmlFor="notes" className={classNames(form.label, style.wideWidth)}>
                        {t("DeliveryHistory.addLicenseDelivery.notes")}
                    </label>
                </div>
                <div className={classNames(style.gridRows, form.optional)}>
                    <textarea
                        id={createElementId("notes")}
                        className={classNames(form.input, form.fixedWidthInput, form.textAreaHeight)}
                        onChange={(event) => props.handleChange(event)}
                        data-testid={testIds.workArea.license.deliveryHistory.createDeliveryDialog.notesTextArea.itself}
                        maxLength={DESCRIPTION_MAX_LENGTH}
                        value={props.deliveryForm.notes}
                    />
                    <span className={form.notes}>
                        {t("DeliveryHistory.addLicenseDelivery.validation.notes", {
                            remainingCharacters: (DESCRIPTION_MAX_LENGTH - props.deliveryForm.notes.length).toString(),
                            maximumNumberOfCharacters: DESCRIPTION_MAX_LENGTH.toString(),
                        })}
                    </span>
                </div>
                <Tooltip content={t("DeliveryHistory.addLicenseDelivery.warning")}>
                    <div className={style.infoIcon}>
                        <Info borderColor={props.theme.contentBackgroundColor} color={props.theme.iconFillColor} />
                    </div>
                </Tooltip>
            </div>
            {isUserParentInternal() && props.licensingModel === LicensingModel.BUNDLE_WITH_TOKEN && (
                <div>
                    <label htmlFor="tokenRateVersion" className={classNames(form.label, style.wideWidth)}>
                        {t("DeliveryHistory.addLicenseDelivery.tokenRateVersions")}
                    </label>
                    <select
                        id={createElementId("tokenRateVersion")}
                        className={classNames(form.select, style.selectArrow, form.fixedWidthInput, {
                            [form.inputError]: formErrors.tokenRateVersion,
                        })}
                        onChange={props.handleChange}
                        value={props.deliveryForm.tokenRateVersion}
                        onClick={() => {
                            usageStatisticsService.sendEvent({
                                category: Category.TOKEN_RATE,
                                action: Action.CHANGE_TOKEN_RATE_VERSION,
                            });
                        }}
                    >
                        {props.rateVersions?.map((value) => (
                            <option key={value.name} value={value.uuid}>
                                {value.name}
                            </option>
                        ))}
                    </select>
                    <div
                        className={form.error}
                        data-testid={
                            testIds.workArea.license.deliveryHistory.createDeliveryDialog.deliveryTypeSelect.errorLabel
                        }
                    >
                        <ErrorMessage name={createElementId("tokenRateVersions")} />
                    </div>
                    <button
                        className={classNames(style.tooltipPosition, buttons.textButton)}
                        type="button"
                        onClick={() => {
                            setBundleTokenPricingModalVisible(true);
                        }}
                    >
                        <div>
                            <PreviewIcon color={theme.linkTextColor} />
                            <span className={style.addMoreButton}>
                                {t("DeliveryHistory.addLicenseDelivery.previewRateTable")}
                            </span>
                        </div>
                    </button>
                </div>
            )}
            {createBatchPoolUpdate()}
            {props.licensingModel != LicensingModel.BUNDLE_WITH_TOKEN ? null : (
                <BundleTokenPricingView
                    visibility={bundleTokenPricingModalVisible}
                    setVisibility={setBundleTokenPricingModalVisible}
                    productToRate={replaceProductIdWithNameInRatesTable(
                        extractProductRatesList(props.rateVersions, extractRateUuid()),
                        deducedTierLicenses
                    )}
                />
            )}
            <div className={form.formFields}>
                <div className={classNames(infoBannerStyle.warningBanner, infoBannerStyle.deliveryFormBanner)}>
                    <div className={infoBannerStyle.textAndBannerContainer}>
                        <Info color={theme.infoIconBackgroundColor} borderColor={theme.contentBorderColor} />
                        <div className={infoBannerStyle.textDisplay}>{t("EditCustomerView.expirationDateBanner")}</div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default connector(DeliveryFormContent);
